import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ConvenioPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ConvenioPage",
            ss: ss,
            ajax: {
                "url": ss.indexConvenio(),
                headers: ss.getToken(),
            },
            
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false,width: '1px' },
                { data: 'NInstitucion', name: 'c.NInstitucion', title: 'Institución', responsivePriority: 1  },
                { data: 'Pais', name: 'pa.Pais', title: 'Pais' },
                // { data: 'Ciudad', name: 'ciu.Ciudad', title: 'Ciudad' },
                { data: 'FechaInicio', name: 'c.FechaInicio', title: 'Fecha Inicio' },
                { data: 'FechaFinal', name: 'c.FechaFinal', title: 'Fecha Fin' },
                { data: 'Vigencia', name: 'c.Vigencia', title: 'Vigencia' },
                { data: 'Activo', name: 'c.Activo', title: 'Estado', orderable: false, searchable: false,
            
                render:function(data, type, row){
                    if (row.Activo == true){
                        return  '<div class=" text-center"><p class="text-success mb-0"> Activo <b> </b></p></div>';
                    }

                    if (row.Activo == false){
                        return  '<div class=" text-center"><p class="text-danger mb-0"> <b> Inactivo </b></p></div>';
                    }

                }

                },
                // { data: 'DiferenciaEnDias',  title: 'Dias Restantes' },

                // { data: 'DiferenciaEnDias', name: 'c.DiferenciaEnDias', title: 'Dias Restantes', searchable: false, render:function(data, type, row){
                //     if (row.DiferenciaEnDias == 0){
                //         return  '<div class=" parpadea btn btn-xs pulse btn-danger text-center text-white pulse-animation" style="width:100%"><p class="text-center mb-0"> <b>'+ row.DiferenciaEnDias+' </b></p></div>';
                //     }

                //     if (row.DiferenciaEnDias <= 90 && row.DiferenciaEnDias != 0){
                //         return  '<div class="  btn btn-xs pulse btn-warning text-center text-white pulse-animation" style="width:100%"><p class="text-center mb-0"> <b>'+ row.DiferenciaEnDias+' </b></p></div>';
                //     }

                //     if (row.DiferenciaEnDias >= 90 && row.DiferenciaEnDias != 0){
                //         return  '<div class="  btn btn-xs pulse btn-success text-center text-white pulse-animation" style="width:100%"><p class="text-center mb-0"> <b>'+ row.DiferenciaEnDias+' </b></p></div>';
                //     }

                // } },



                {
                    data: 'diff',
                    orderable: false,
                    title: 'Dias Restantes',
                    searchable: false,

                    render:function(data, type, row){
                            if (row.diff == 0){
                                return  '<div class=" parpadea btn btn-xs pulse btn-danger text-center text-white pulse-animation" style="width:100%"><p class="text-center mb-0"> <b>'+ row.diff+' </b></p></div>';
                            }
        
                            if (row.diff <= 90 && row.diff != 0){
                                return  '<div class="  btn btn-xs pulse btn-warning text-center text-white pulse-animation" style="width:100%"><p class="text-center mb-0"> <b>'+ row.diff+' </b></p></div>';
                            }
        
                            if (row.diff >= 90 && row.diff != 0){
                                return  '<div class="  btn btn-xs pulse btn-success text-center text-white pulse-animation" style="width:100%"><p class="text-center mb-0"> <b>'+ row.diff+' </b></p></div>';
                            }
        
                        }

                },


                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                    width: '1px',
                    responsivePriority: 1
                },


                

            ],
            convenios: [],
            ciudads: [],
            convenio: {},
            isLoading: false,
            errorBag: {},
            tiposconvenio:[],
            paises:[],
            instituciones:[],
            isLoadingFile:false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            tiposinsts:[],
            unidadAcademicas:[]

        };
    },
    methods: {

        getTipoInstitucion() {
            this.ss.listTipoInstitucion().then(
                (result) => {
                    let response = result.data;
                    this.tiposinsts = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.convenio.Archivo = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        newConvenio() {
            this.convenio = {};
            this.convenio.Activo = false;
            this.$refs['frm-convenio'].show();
        },
        showConvenio(id) {
            this.isLoading=true;
            this.ss.showConvenio(id).then(
                (result) => {
                    let response = result.data;
                    this.convenio = response.data;
                    this.$refs['view-convenio'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        getCiudad() {
            this.ss.listCiudad().then(
                (result) => {
                    let response = result.data;
                    this.ciudads = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCiudad2() {
            this.ss.list2Ciudad(this.convenio.Pais).then(
                (result) => {
                    let response = result.data;
                    this.ciudads = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },


        getTipoConvenio() {
            this.ss.listTipoConvenio().then(
                (result) => {
                    let response = result.data;
                    this.tiposconvenio = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPais() {
            this.ss.listPais().then(
                (result) => {
                    let response = result.data;
                    this.paises = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getInstitucion() {
            this.ss.listInstitucion().then(
                (result) => {
                    let response = result.data;
                    this.instituciones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        editConvenio() {
            this.$refs['frm-convenio'].show();
            this.$refs['view-convenio'].hide();
        },
        cancelConvenio() {
            if (this.convenio.id) {
                this.$refs['view-convenio'].show();
            }
            this.$refs['frm-convenio'].hide();
        },
        saveConvenio() {

            // if(!this.convenio.Activo){
            //     this.$swal({
            //         title: "Datos Incompletos",
            //         text: "Debe indicar si el convenio esta activo o no",
            //         icon: "warning",
            //         buttons: true,
            //         dangerMode: true,
            //     })
            //     return;
            // }

            if(!this.convenio.TipoConvenio){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe seleccionar un Tipo Convenio",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }
            if(!this.convenio.Anio){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe llenar el campo año",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.Pais){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe seleccionar un Pais",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.Ciudad){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe seleccionar una Ciudad",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            // if(!this.convenio.Institucion){
            //     this.$swal({
            //         title: "Datos Incompletos",
            //         text: "Debe seleccionar una Institucion",
            //         icon: "warning",
            //         buttons: true,
            //         dangerMode: true,
            //     })
            //     return;
            // }

            if(!this.convenio.NInstitucion){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe llenar el campo Institucion",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.TipoInstitucion){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe seleccionar un Tipo de Institucion",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.Objeto){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe llenar el campo Objeto",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.FechaInicio){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe llenar el campo Fecha Inicio",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }


            if(!this.convenio.FechaFinal){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe llenar el campo Fecha Final",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }


            if(!this.convenio.Vigencia){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe llenar el campo Vigencia",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.UnidadAcademica){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe seleccionar una Unidad Académica",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }

            if(!this.convenio.Archivo){
                this.$swal({
                    title: "Datos Incompletos",
                    text: "Debe cargar el Archivo escaneado del convenio",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                return;
            }



            this.ss.storeConvenio(this.convenio).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-convenio'].hide();
                    this.$refs['datatable-convenio'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteConvenio() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyConvenio(this.convenio)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-convenio'].hide();
                                this.$refs['datatable-convenio'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-Convenio').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showConvenio(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        }else{
            // this.getCiudad();
            this.getPais();
            this.getTipoConvenio();
            this.getInstitucion();
            this.getTipoInstitucion();
            this.getUnidadAcademica();

        }
    }
};
