import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import PaisPage from '@/pages/Pais/PaisPage.vue'
import CiudadPage from '@/pages/Ciudad/CiudadPage.vue'
import TipoConvenioPage from '@/pages/TipoConvenio/TipoConvenioPage.vue'
import InstitucionPage from '@/pages/Institucion/InstitucionPage.vue'
import ConvenioPage from '@/pages/Convenio/ConvenioPage.vue'
import TipoInstitucionPage from '@/pages/TipoInstitucion/TipoInstitucionPage.vue'

import ReportesPage from '@/pages/Reportes/ReportesPage.vue'

import ManualPage from '@/pages/Manual/ManualPage.vue'

import InfoConveniosPage from '@/pages/InfoConvenios/InfoConveniosPage.vue'
import ConveniosInfoPage from '@/pages/ConveniosInfo/ConveniosInfoPage.vue'



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    }
    ,
    {
      path: '/Pais',
      name: 'Pais',
      component: PaisPage
    }
    ,
    {
      path: '/Ciudad',
      name: 'Ciudad',
      component: CiudadPage
    }
    ,
    {
      path: '/TipoConvenio',
      name: 'TipoConvenio',
      component: TipoConvenioPage
    },

    {
      path: '/TipoInstitucion',
      name: 'TipoInstitucion',
      component: TipoInstitucionPage
    }
    ,
    {
      path: '/Institucion',
      name: 'Institucion',
      component: InstitucionPage
    }

    ,
    {
      path: '/Convenio',
      name: 'Convenio',
      component: ConvenioPage
    }

    ,
    {
      path: '/Reportes',
      name: 'Reportes',
      component: ReportesPage
    },
    {
      path: '/Manual',
      name: 'Manual',
      component: ManualPage
    },
    {
      path: '/InfoConvenios',
      name: 'InfoConvenios',
      component: InfoConveniosPage
    },

    {
      path: '/ConveniosInfo',
      name: 'ConveniosInfo',
      component: ConveniosInfoPage
    },

  ]
})