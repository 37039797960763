import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ConveniosInfoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ConvenioPage",
            ss: ss,
            ajax: {
                "url": ss.indexInfoConvenio(),
                headers: ss.getToken(),
            },
            
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false,width: '1px' },
                { data: 'NInstitucion', name: 'c.NInstitucion', title: 'Institución', responsivePriority: 1  },
                { data: 'Objeto', name: 'c.Objeto', title: 'Objeto' },
                // { data: 'Pais', name: 'pa.Pais', title: 'Pais' },
                // { data: 'Ciudad', name: 'ciu.Ciudad', title: 'Ciudad' },
                // { data: 'FechaInicio', name: 'c.FechaInicio', title: 'Fecha Inicio' },
                // { data: 'FechaFinal', name: 'c.FechaFinal', title: 'Fecha Fin' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica' },
                { data: 'TipoInstitucion', name: 'tin.TipoInstitucion', title: 'Tipo Institución' },
                // { data: 'Activo', name: 'c.Activo', title: 'Estado', orderable: false, searchable: false,
            
                // render:function(data, type, row){
                //     if (row.Activo == true){
                //         return  '<div class=" text-center"><p class="text-success mb-0"> Activo <b> </b></p></div>';
                //     }

                //     if (row.Activo == false){
                //         return  '<div class=" text-center"><p class="text-danger mb-0"> <b> Inactivo </b></p></div>';
                //     }

                // }

                // },
                



                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                    width: '1px',
                    responsivePriority: 1
                },


                

            ],
            convenios: [],
            ciudads: [],
            convenio: {},
            isLoading: false,
            errorBag: {},
            tiposconvenio:[],
            paises:[],
            instituciones:[],
            isLoadingFile:false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            tiposinsts:[],
            unidadAcademicas:[]

        };
    },
    methods: {

        getUnidadAcademica() {
            this.ss.listUnidadAcademicados().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, 
        showConvenio(id) {
            this.isLoading=true;
            this.ss.showInfoConvenio(id).then(
                (result) => {
                    let response = result.data;
                    this.convenio = response.data;
                    this.$refs['view-convenio'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        filtro(){
           
            // let estadoLibro = document.getElementById('EstadoLibro').value;
            // let rep = this.reparticion.Reparticion;
            // let cat = this.categoria.Categoria;

            // if(estadoLibro == "Todos") estadoLibro = null;
            let idua = this.convenio.UnidadAcademica;

            this.ajax.data = function (d) {
                 
                 d.UnidadAcademica = idua;
                //  console.log(d.UnidadAcademica);

            }
            this.$refs['datatable-convenio'].reloadAjax(this.ajax);
        },

        desfiltro(){
            this.convenio = {};
            this.ajax.data = function (d) {
                 
                 d.UnidadAcademica = 0;

            }
            this.$refs['datatable-convenio'].reloadAjax(this.ajax);
        },
       

        draw() {
            window.$('.btn-datatable-Convenio').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showConvenio(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        
       
        this.getUnidadAcademica();

        
    }
};
