import MainService from "@/services/MainService.js";
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "ReportesPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ReportesPage",
            ss: ss,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            convenio:{},
            errorBag:{},
            unidadAcademicas:[],
            auth:{}

            
              

                        
        };
        
    },
    methods: {
      
      DescargarRepUno(){
        // console.log("descargar reporte uno");
        // let url = "api/get_excel?Programa="+this.programaElegido.id;
        let url = "api/get_excel";
        console.log(this.rutaApp+url);
        window.open(this.rutaApp+url);
      },

      DescargarRepGenUno(){
        // console.log("descargar reporte uno");
        // let url = "api/get_excel?Programa="+this.programaElegido.id;
        let url = "api/get_excel?GeneralActivos=1";

        console.log(this.rutaApp+url);
        window.open(this.rutaApp+url);
      },

      DescargarRepGenDos(){
        // console.log("descargar reporte uno");
        // let url = "api/get_excel?Programa="+this.programaElegido.id;
        let url = "api/get_excel?GeneralNoActivos=1";

        console.log(this.rutaApp+url);
        window.open(this.rutaApp+url);
      },

      DescargarRepDos(){
        // console.log("descargar reporte uno");
        // let url = "api/get_excel?Programa="+this.programaElegido.id;
        let url = "api/get_excel?FechaInicio="+this.convenio.FechaInicio+"&FechaFinal="+this.convenio.FechaFinal+"&UnidadAcademica="+this.convenio.UnidadAcademica;
        console.log(this.rutaApp+url);
        window.open(this.rutaApp+url);
      },

      modalrep(){
        this.convenio={};
        this.$refs['fechasrep'].show();
        
      },

      cancelmodalrep(){
        this.$refs['fechasrep'].hide();
        
      },

      
      getUnidadAcademica() {
        this.ss.listUnidadAcademica().then(
            (result) => {
                let response = result.data;
                this.unidadAcademicas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },
          
          
          
    },
    
    components: {
        VueApexCharts
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!persona) {
          this.$router.push('/Login');
        }
        else{
          this.getUnidadAcademica();
            
        }
    }
};
